<template>
    <OMediaQueryProvider v-slot="{ isTouch }">
        <div :style="{ 'min-height:200px;': filterItem.showDistinct }">
            <BasicFilter v-if="!isTouch" :filterItem="filterItem" :filterObject="filterObject" 
                :dropdown="dropdown" :hideOperators="hideOperators" @onInput="onInput"/>

            <div class="mb-2 mt-2 pt-2 d-flex" :class="{ 'border-top': !isTouch }" v-if="filterItem.showDistinct">
                <input v-model="distincSearchValue" type="search" @search="doSearch" class="form-control form-control-sm rounded-0 mt-1" :placeholder="$t('Search...')">

                <button class="btn btn-link text-decoration-none px-1 py-0" @click="sortOn('value')" 
                    :class="{ 'text-muted': distinctHandler.sortType != 'value' }" :title="$t('Sort alphabetically')">
                    <i class="fs-5 bi" :class="distinctHandler.valueSortDirection === 'asc' ? 'bi-sort-alpha-down-alt' : 'bi-sort-alpha-down'"></i>
                </button>

                <button class="btn btn-link text-decoration-none px-1 py-0" @click="sortOn('count')" 
                    :class="{'text-muted': distinctHandler.sortType != 'count'}" :title="$t('Sort on count')">
                    <i class="fs-5 bi" :class="distinctHandler.countSortDirection === 'asc' ? 'bi-sort-down-alt' : 'bi-sort-down'"></i>
                </button>
            </div>

            <div v-if="distinctHandler.isLoading && filterItem.showDistinct" class="d-flex" style="height:150px;" >
                <div class="spinner-border m-auto" style="width:2rem;height:2rem;" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>

            <template v-if="distinctHandler.data.length > 0 && !distinctHandler.isLoading && filterItem.showDistinct" >
                <div class="form-check mx-2">
                    <input class="form-check-input" type="checkbox" @click="selectAll()" :checked="allSelected" :id="distinctId">
                    <label class="form-check-label" :for="distinctId">
                        {{$t('Select all')}} 
                    </label>
                </div>

                <div ref="elRef" class="ms-1 border position-relative" style="max-height:250px;overflow:hidden auto;" @scroll="handleScroll">
                    <div class="wrapper" :style="{ height: distinctHandler.data.length * 24 + 'px' }">
                        <div v-for="item in scrollData" :key="item.index" class="form-check mx-1 position-absolute" style="height:24px;width:97%" :style="{ 'transform': 'translateY('+item.pos + 'px)' }">
                            <input :checked="item.item.isSelected" class="form-check-input" type="checkbox" 
                                @input="event => toggleItem(event, item.item)" :id="`${distinctId}-${item.index}`">
                            <label class="form-check-label text-truncate position-relative" style="max-width: 100%; padding-top: 1px;" :title="`${item.item.Value} (${item.item.Count})`" :for="`${distinctId}-${item.index}`">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="text-truncate">{{ item.item.isUnAllocated ? $t('-unallocated-') : item.item.Value ?? $t('-blank-') }}</div>
                                    <div v-if="!hideCounts" class="ms-1">({{ item.item.Count }})</div>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </OMediaQueryProvider>

     <ApplyFilter :filterItem="filterItem" :filterObject="filterObject" :dropdown="dropdown" class="mt-2"></ApplyFilter>
</template>

<script setup lang="ts">
import type { FilterObject, FilterItem} from 'o365-filterobject';
import { OApplyFilter as ApplyFilter, OBasicFilterEditor as BasicFilter } from 'o365-filter-components';
import DistinctPropertiesHandler from './Filter.DistinctPropertiesHandler.ts';
import { useVirtualScroll } from 'o365-vue-utils';
import { OMediaQueryProvider } from 'o365-ui-components';
// import BasicFilter from 'o365.vue.components.FilterEditor.Basic.vue';
// import OMediaQueryProvider from 'o365.vue.components.MediaQueryProvider.vue';
import { computed, ref, onMounted } from 'vue';

const props = defineProps<{
    filterItem: FilterItem,
    filterObject: FilterObject,
    dropdown?: any,
    hideOperators?: boolean
}>();

if (!(props.filterItem._distinctHandler instanceof DistinctPropertiesHandler)) {
    props.filterItem._distinctHandler = new DistinctPropertiesHandler(props.filterObject.dataObject, {
        propertyName: props.filterItem.field.split('Property.')[1]
    });
}

const distinctHandler = computed(() => props.filterItem.distinctHandler as DistinctPropertiesHandler);
const elRef = ref<HTMLElement | null>(null);
const { handleScroll, scrollData } = useVirtualScroll({
    dataRef: distinctHandler.value.data,
    watchTarget: () => distinctHandler.value.updated,
    itemSize: 24,
    itemsToRender: 40,
    recycleList: true,
    elementRef: elRef
});

const hideCounts = computed(() => props.filterItem.distinctOptions?.hideCounts === true);

const distinctId = `properties-distinct-${crypto.randomUUID()}`;
const allSelected = computed(() => {
    return distinctHandler.value.data.every(x => x.isSelected);
});
const distincSearchValue = computed({
    get() {
        return distinctHandler.value.searchString;
    },
    set(newValue) {
        distinctHandler.value.searchString = newValue;;
        doDebounceSearch();
    }
});

let searchDebounce: number | null = null;
function doDebounceSearch() {
    if (searchDebounce) { window.clearTimeout(searchDebounce); }
    searchDebounce = window.setTimeout(() => {
        doSearch();
        searchDebounce = null;
    }, 500);
}

function onInput() {
    if (!props.filterItem.selectedValue) {
        // clear all selected distinct
    }
}

function selectAll() {
    const selected = !allSelected.value;
    distinctHandler.value.data.forEach((item) => {
        item.isSelected = selected;
        if(selected) distinctHandler.value.selectedValues.add(item.Value ?? '');
    });
    if(!selected){
        distinctHandler.value.selectedValues.clear()
    }
    updateFilterItem();
}

function toggleItem(e: InputEvent, pItem: { Value: string, isSelected: boolean, isUnAllocated?: boolean }) {
    pItem.isSelected = (e.target as HTMLInputElement).checked;
    if (pItem.isSelected) {
        distinctHandler.value.selectedValues.add(pItem.Value ?? '');
    } else {
        distinctHandler.value.selectedValues.delete(pItem.Value ?? '');
    }
    updateFilterItem();
}

function updateFilterItem() {
    props.filterItem.operator = 'inlist';
    const values = Array.from(distinctHandler.value.selectedValues);
    props.filterItem.selectedValue = values.length === 0 ? null : values;
    props.filterItem.expressionValue = props.filterItem.selectedValue;
}

function doSearch() {
    distinctHandler.value.load();
}

function sortOn(pType: 'value' | 'count') {
    if (distinctHandler.value.sortType == pType) {
        if (pType === 'value') {
            distinctHandler.value.valueSortDirection = distinctHandler.value.valueSortDirection === 'asc'
                ? 'desc'
                : 'asc';
        } else {
            distinctHandler.value.countSortDirection = distinctHandler.value.countSortDirection === 'asc'
                ? 'desc'
                : 'asc';
        }
    } else {
        distinctHandler.value.sortType = pType
    }
    distinctHandler.value.load();
}

onMounted(() => {
    distinctHandler.value.searchString = '';
    distinctHandler.value.load();
});

</script>
